import backdrop from '../../assets/video_bg_pp_iphone.mp4'

import './backdrop.scss'

export default function Backdrop() {
    return (
        <video className="backdrop-container" autoPlay playsinline  muted loop>
            <source src={backdrop} type="video/mp4" />
        </video>
    )
}