import { useState } from "react";
import { Steps, useSteps } from "react-step-builder";

import StepInicio from "../../pages/inicio/stepInicio";
import StepUm from "../../pages/step1/step1";
import StepDois from "../../pages/step2/step2";
import StepTres from "../../pages/step3/step3";
import StepQuatro from "../../pages/step4/step4";
import StepCinco from "../../pages/step5/step5";
import StepSeis from "../../pages/step6/step6";
import StepSete from "../../pages/step7/step7";
import StepOito from "../../pages/step8/step8";
import StepNove from "../../pages/step9/step9";
import StepFim from "../../pages/fim/stepFim";
import Transtion from "../../pages/transition/transition";

import logo from "../../assets/logo_pp.svg";

import { postDataFromApi } from "../../services/api";
import { getLinkGoogleApi } from "../../services/api";

export default function StepsForm() {
  const { current, isLast } = useSteps();
  const [data, setData] = useState();
  const [isPromotores, setIsPromotores] = useState();
  const [linkGoogle, setlinkGoogle] = useState();

  async function onChange(field, value) {
    await setData({ ...data, [field]: value });
  }

  function onFinish() {
    postDataFromApi(data);
  }

  function onPromotores() {
    setIsPromotores(true);
    let res = getLinkGoogleApi(data);
    if (res) {
      res.then((values) => {
        setlinkGoogle(values);
      });
    }
  }

  return (
    <div>
      {/* current:3 Transition step */}
      {current > 2 && !isLast && (
        <div className="header">
          <img className="logo-sm" src={logo} alt="logotipo da empresa" />
        </div>
      )}
      <Steps>
        <StepInicio onChange={onChange} />
        {/* <StepIntroducao /> */}
        <Transtion />
        <StepUm onChange={onChange} onPromotores={onPromotores} />
        <StepDois onChange={onChange} />
        <StepTres onChange={onChange} />
        <StepQuatro onChange={onChange} />
        <StepCinco onChange={onChange} />
        <StepSeis onChange={onChange} />
        <StepSete onChange={onChange} isPromotores={isPromotores} />
        <StepOito
          onChange={onChange}
          isPromotores={isPromotores}
          onFinish={onFinish}
        />
        <StepNove
          onChange={onChange}
          isPromotores={isPromotores}
          onFinish={onFinish}
        />
        <StepFim
          isPromotores={isPromotores}
          linkGoogle={linkGoogle}
          onChange={onChange}
          onFinish={onFinish}
        />
      </Steps>
    </div>
  );
}
