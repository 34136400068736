import React, { useState } from 'react';
import { useSteps } from "react-step-builder";

import Rating from "../../components/rating/rating"

export default function StepUm(props) {
    const stepsState = useSteps();

    function onClickPreviousStep() {
        stepsState.prev()
    }

    function onChangeValue(value) {
        props.onChange('step1', value)
        if(value == '9' || value == '10'){
            props.onPromotores()
            stepsState.jump(9)
        }else {
            stepsState.next()
        }
    }
    return (
        <div className="centered-container faded" >            
            <span className="step-title center">Então vamos lá!</span>
            <span className="subtitle left">Em uma escala de 0 a 10, <b><u>qual é o seu nível de satisfação</u></b> com a Farmácia Preço Popular?</span>
            <Rating value="1" onChange={onChangeValue} />
            <div className="step-form-actions">
                <span onClick={onClickPreviousStep}><img src="img/back_icon.png"></img> Voltar</span>
            </div>
        </div>
    )
}